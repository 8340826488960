import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Link } from "react-router-dom";
import { updateSelectedGroup } from "./../../../store/reducers/auth";
import ProfileMenu from "./ProfileMenu";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownGroupOpen: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleGroupDropdown = this.toggleGroupDropdown.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }

  toggleGroupDropdown() {
    this.setState((prevState) => ({
      isDropdownGroupOpen: !prevState.isDropdownGroupOpen,
    }));
  }

  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  handleGroupChange = (e, group) => {
    e.preventDefault();
    this.props.dispatch(updateSelectedGroup(group));
  };

  handleGroupChangeBlank = (e) => {
    e.preventDefault();
    this.props.dispatch(updateSelectedGroup({ _id: "", name: "" }));
  };

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header navbar-header-padding">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/enterprise/dashboard" className="logo logo-dark">
                  <span className="logo-sm">
                    <img
                      src={this.props.user.enterpriseId.enterprise_logo}
                      alt={this.props.user.enterpriseId.company_name}
                      height="22"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      src={this.props.user.enterpriseId.enterprise_logo}
                      alt={this.props.user.enterpriseId.company_name}
                      height="60px"
                    />
                  </span>
                </Link>
              </div>

              {this.props.showMenuToggle && (
                <Button
                  size="sm"
                  color="none"
                  type="button"
                  onClick={this.toggleMenu}
                  className="px-3 font-size-24 header-item waves-effect"
                  id="vertical-menu-btn"
                >
                  <i className="ri-menu-2-line align-middle"></i>
                </Button>
              )}
            </div>

            <div className="d-flex">
              {this.props.showMenuToggle ? (
                <React.Fragment>
                  {!!this.props.user.groups && (
                    <ButtonGroup className="mt-3 mr-2 mb-sm-0">
                      <Dropdown
                        isOpen={this.state.isDropdownGroupOpen}
                        toggle={this.toggleGroupDropdown}
                      >
                        <DropdownToggle
                          tag="button"
                          className="btn btn-light waves-light waves-effect dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            backgroundColor: "#FFFFFF",
                            borderColor: "#E4E4E4",
                            boxShadow: "0px 3px 6px #00000017",
                          }}
                        >
                          {!_.isEmpty(this.props.selectedGroupVal)
                            ? this.props.selectedGroupVal
                            : "All Groups"}
                          <i className="mdi mdi-chevron-down ml-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {!_.isEmpty(this.props.selectedGroupVal) && (
                            <DropdownItem
                              tag="a"
                              href="#"
                              onClick={this.handleGroupChangeBlank}
                            >
                              All Groups
                            </DropdownItem>
                          )}
                          {this.props.user.groups.map((group) => {
                            return (
                              <DropdownItem
                                tag="a"
                                href="#"
                                key={group._id}
                                onClick={(e) =>
                                  this.handleGroupChange(e, group)
                                }
                              >
                                {group.name}
                              </DropdownItem>
                            );
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    </ButtonGroup>
                  )}
                  <div className="dropdown d-none ml-1">
                    <Button
                      color="none"
                      type="button"
                      className="header-item noti-icon waves-effect"
                      onClick={this.toggleFullscreen}
                    >
                      <i className="ri-fullscreen-line"></i>
                    </Button>
                  </div>
                </React.Fragment>
              ) : (
                <Link
                  className="waves-effect waves-light mr-1 btn btn-outline-primary pt-1 h-100 mt-3 font-weight-bold"
                  to="/enterprise/dashboard"
                >
                  Go to Dashboard
                </Link>
              )}

              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    selectedGroup: state.auth.selectedGroup,
    selectedGroupVal: state.auth.selectedGroupVal,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Header);

import React, { Component } from "react";
import { Link } from "react-router-dom";

class SearchBarMobile extends Component {
  render() {
    return (
      <React.Fragment>
        <div
          className="header--search-bar--2rQ3t header--search-bar-layer--V7PsO search-mobile"
          role="dialog"
          tabIndex="-1"
          aria-labelledby="header-mobile-search-bar-title"
          aria-modal="true"
        >
          <div
            id="header-mobile-search-bar"
            className="gt-search-form-autocomplete header--search-bar-form--1IPCf gt-form-group"
          >
            <form
              action="#"
              method="POST"
              className="gt-search-form-autocomplete-input-group gt-search-form-autocomplete-input-group-reversed"
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                type="text"
                name="search"
                onChange={this.props.onChange}
                value={this.props.searchValue}
                placeholder="Search for courses"
                autoComplete="off"
                aria-autocomplete="both"
                aria-haspopup="true"
                aria-expanded="true"
                aria-controls="header-mobile-search-bar-menu-content-items"
                id="header-mobile-search-bar"
                className="ud-text-input ud-text-input-large ud-text-md gt-search-form-autocomplete-input js-header-search-field"
              />
              <ul
                id="header-mobile-search-bar-menu-content-items"
                data-testid="menu-content-items"
                className="gt-unstyled-list gt-block-list gt-search-form-autocomplete-suggestions-with-image"
                role="listbox"
              >
                <li>
                  {/* <div
                    data-testid="result-with-image"
                    className="gt-search-form-autocomplete-suggestion-block-list-item"
                  >
                    <a
                      id="header-mobile-search-bar-0"
                      aria-label="Search: javascript"
                      aria-selected="false"
                      role="option"
                      href="/courses/search/?q=javascript&amp;src=sac&amp;kw=javascript"
                      className="ud-btn ud-btn-large ud-btn-ghost ud-text-md ud-search-form-autocomplete-suggestion ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral"
                    >
                      <div className="gt-block-list-item-content">
                        <div
                          aria-hidden="true"
                          className="gt-search-form-autocomplete-group ud-search-form-autocomplete-group-search"
                          data-testid="group-search"
                        >
                          <div
                            data-testid="menu-content-icon"
                            data-type="search_log"
                            className="gt-search-form-autocomplete-group-icon"
                          >
                            <i className="fa fa-search"></i>
                          </div>
                          <div className="gt-search-form-autocomplete-suggestion-content">
                            <div
                              data-purpose="label"
                              data-testid="label"
                              className="gt-search-form-autocomplete-suggestion-content gt-heading-md"
                            >
                              javascript
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div> */}
                  {!!this.props.searchData &&
                    this.props.searchData.map((course) => {
                      return (
                        <div
                          className="gt-search-form-autocomplete-suggestion-block-list-item"
                          key={course._id}
                        >
                          <Link
                            aria-label={`Course: ${course.courseName} - Institution: ${course.courseProviderName}`}
                            aria-selected="false"
                            role="option"
                            to={`/marketplace/course-detail/${course._id}`}
                            className="gt-search-form-autocomplete-suggestion"
                          >
                            <div className="gt-block-list-item-content">
                              <div
                                aria-hidden="true"
                                className="gt-search-form-autocomplete-group"
                              >
                                <div className="gt-search-form-autocomplete-group-course-img">
                                  <img
                                    alt={`${course.courseName}`}
                                    src={`${course.courseImgUrl}`}
                                    width="32"
                                    height="32"
                                  />
                                </div>
                                <div className="gt-search-form-autocomplete-suggestion-content">
                                  <div className="gt-search-form-autocomplete-suggestion-content gt-heading-md">
                                    {course.courseName}
                                  </div>
                                  <div className="gt-search-form-autocomplete-suggestion-details">
                                    <div className="gt-heading-xs">
                                      Institution
                                    </div>
                                    <div className="gt-text-xs gt-search-form-autocomplete-suggestion-instructor-name">
                                      {course.courseProviderName}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                </li>
              </ul>
              <i className="mdi mdi-magnify search-icon"></i>
              <i
                className="mdi mdi-close gt-searchbtnclose header--search-bar-close--1YT2F"
                onClick={this.props.handleMobileSearchToggle}
              ></i>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SearchBarMobile;

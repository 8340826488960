// Actions
const TOGGLE_INSTITUTION_MENU = "TOGGLE_INSTITUTION_MENU";
const TOGGLE_SEARCH = "TOGGLE_SEARCH";
const TOGGLE_MOBILE_SEARCH = "TOGGLE_MOBILE_SEARCH";
const START_COURSE_LOADER = "START_COURSE_LOADER";
const STOP_COURSE_LOADER = "STOP_COURSE_LOADER";
const UPDATE_FILTER_OPTION = "UPDATE_FILTER_OPTION";
const TOGGLE_CONTACTUS_MODAL = "TOGGLE_CONTACTUS_MODAL";

// Action Creators
export function toggleInstitutionMenu() {
  return { type: TOGGLE_INSTITUTION_MENU };
}

export function toggleSearch() {
  return { type: TOGGLE_SEARCH };
}

export function toggleMobileSearch() {
  return { type: TOGGLE_MOBILE_SEARCH };
}

export function startCourseLoader() {
  return { type: START_COURSE_LOADER };
}

export function stopCourseLoader() {
  return { type: STOP_COURSE_LOADER };
}

export function toggleContactUsModal(payload) {
  return { type: TOGGLE_CONTACTUS_MODAL, payload };
}

export function updateFilterOptionAction(payload) {
  return { type: UPDATE_FILTER_OPTION, payload };
}

const initialState = {
  homeCoursesData: [],
  categories: [],
  enterprises: [],
  filters: {
    search: "",
    institutes: [],
    categories: [],
    rating: undefined,
    page: 1,
  },
  totalCoursesCount: 0,
  totalUsersCounts: 0,
  institutionOpen: false,
  courseLoader: false,
  modal_contactus: false,
  contactUsData: {},
  isSearchOpen: false,
  isMobileSearchOpen: false,
};

// Reducer
export default function reducer(
  state = initialState,
  { type, payload = null }
) {
  switch (type) {
    case TOGGLE_INSTITUTION_MENU:
      return {
        ...state,
        institutionOpen: !state.institutionOpen,
      };
    case TOGGLE_SEARCH:
      return {
        ...state,
        isSearchOpen: !state.isSearchOpen,
      };
    case TOGGLE_MOBILE_SEARCH:
      return {
        ...state,
        isMobileSearchOpen: !state.isMobileSearchOpen,
      };
    case START_COURSE_LOADER:
      return {
        ...state,
        courseLoader: true,
      };
    case STOP_COURSE_LOADER:
      return {
        ...state,
        courseLoader: false,
      };
    case TOGGLE_CONTACTUS_MODAL:
      return updateContactUsDataAndModal(state, payload);
    case UPDATE_FILTER_OPTION:
      return updateFilterOptions(state, payload);
    default:
      return state;
  }
}

function updateFilterOptions(state, payload) {
  return {
    ...state,
    filters: { ...payload },
  };
}

function updateContactUsDataAndModal(state, payload) {
  return {
    ...state,
    modal_contactus: !state.modal_contactus,
    contactUsData: { ...payload },
  };
}
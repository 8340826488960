import Http from "./../../utils/Http";
// import moment from "moment-timezone";

const apiBase = "/api/v1/marketplace/";

export function getHomePageContent(params) {
  let url = apiBase + "get-homepage";
  return Http.post(url, params);
}

export function getHomePageContnetWithFilters(params) {
  let url = apiBase + "get-all-courses-home";
  return Http.post(url, params);
}

export function getInstitutePageContent(params) {
  let url = apiBase + "get-institute";
  return Http.post(url, params);
}

export function marketplaceContactForm(params) {
  let url = apiBase + "contact-form-home";
  return Http.post(url, params);
}

export function getCourseDetials(params) {
  let url = apiBase + "get-course-details";
  return Http.post(url, params);
}

export function generateClientSecret(params) {
  let url = apiBase + "generate-secret";
  return Http.post(url, params);
}

export function checkEnrollmentStatus(params) {
  let url = apiBase + "check-enrollment-status";
  return Http.post(url, params);
}

export function enrollToCourse(params) {
  let url = apiBase + "enroll-course";
  return Http.post(url, params);
}

export function sendContactUsDetials(params) {
  let url = apiBase + "send-contact-us-details";
  return Http.post(url, params);
}

export function getSearchList(params) {
  let url = apiBase + "get-search-list";
  return Http.post(url, params);
}

export function getUserDetails(params) {
  let url = apiBase + "get-user-details";
  return Http.post(url, params);
}
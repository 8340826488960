import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import Header from "./Header";
import Footer from "./Footer";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    };
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  scrollToContactUs = async (e) => {
    e.preventDefault();
    if (this.props.match.path != "/marketplace/home") {
      await this.props.history.push("/marketplace/home");
    }
    let position = document.getElementById("contactUsSection");
    let _pos = position.offsetTop - 80;
    window.scrollTo({
      top: _pos,
      behavior: "smooth",
    });
  };

  componentDidMount() {
    // Scroll Top to 0
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>GetMee - Education Marketplace</title>
        </Helmet>
        <Header scrollToContactUs={this.scrollToContactUs} />
        {this.props.children}
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};
export default connect(mapStatetoProps)(withRouter(Layout));

// import lib
import { lazy } from "react";

const marketPlaceRoutes = [
  {
    path: "/marketplace/home",
    exact: true,
    marketplace: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "marketplace-home" */ "./../components/marketplace/home"
      )
    ),
  },
  {
    path: "/marketplace/course-detail/:id",
    exact: true,
    marketplace: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "marketplace-courseDetail" */ "./../components/marketplace/courseDetail"
      )
    ),
  },
  {
    path: "/marketplace/institution/:id",
    exact: true,
    marketplace: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "marketplace-institutionPage" */ "./../components/marketplace/institution"
      )
    ),
  },
  {
    path: "/marketplace/paymentstatus",
    exact: true,
    marketplace: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "marketplace-paymentStatus" */ "./../components/marketplace/paymentStatus"
      )
    ),
  },
];

export default marketPlaceRoutes;

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Button,
} from "reactstrap";
import marketPlaceLogoImg from "./../../../assets/images/marketplace-logo.png";
import arrow_left from "./../../../assets/images/arrow_left.svg";
import {
  startLoader,
  stopLoader,
} from "../../../store/reducers/generalActions";
import { toggleContactUsModal } from "./../../../store/reducers/marketplace";
import MSG, { notifySuccess, notifyError } from "./../../../utils/Helper";
import { sendContactUsDetials } from "./../services";

class MarketPlaceContactUs extends Component {
  state = {
    name: "",
    email: "",
    mobileNo: "",
    message: "",
  };

  toggleModalContactUs = () => {
    this.props.dispatch(toggleContactUsModal({}));
  };

  handleChange = ({ currentTarget: input }) => {
    this.setState({
      [input.name]: input.value,
    });
  };

  handlePhoneInputChange = (phone, country, e, formattedValue) => {
    let mobileNo, countryDialCode;
    if (!!country.dialCode) {
      mobileNo = phone.substring(country.dialCode.length);
      countryDialCode = country.dialCode;
    }
    // now check if starting is with 0 then remove it
    mobileNo = mobileNo.replace(/^0+/, "");
    // console.log("mobileNo: ", mobileNo);
    this.setState({
      mobileNo: countryDialCode + mobileNo,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, mobileNo, message } = this.state;
    if (_.isEmpty(_.trim(name))) {
      notifyError("Please Enter Name");
      return false;
    }
    if (_.isEmpty(_.trim(email))) {
      notifyError("Please Enter Email");
      return false;
    }
    let pattern = new RegExp(/^[0-9\b\+]+$/);
    if (!pattern.test(mobileNo)) {
      notifyError("Invalid Mobile Number");
      return false;
    }
    if (_.isEmpty(_.trim(message))) {
      notifyError("Please Enter Message");
      return false;
    }
    this.props.dispatch(startLoader());
    try {
      const { data: res } = await sendContactUsDetials({
        data: this.state,
        contactUsData: this.props.contactUsData,
      });
      if (res.success == true) {
        notifySuccess(res.message);
        this.setState({
          name: "",
          email: "",
          mobileNo: "",
          message: "",
        });
        this.props.dispatch(toggleContactUsModal({}));
      } else {
        notifyError(res.message);
        // redirect to home page
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.props.dispatch(stopLoader());
  };

  render() {
    const { course } = this.props.contactUsData;
    return (
      <React.Fragment>
        {this.props.modal_contactus && (
          <Modal
            size="xl"
            isOpen={this.props.modal_contactus}
            toggle={this.toggleModalContactUs}
            onClosed={this.toggleModalContactUs}
            className="enroll_now"
            shouldCloseOnOverlayClick={false}
          >
            <ModalBody>
              <Row className="step1">
                <Col lg={6} className="">
                  <div className="popup_left">
                    <div className="popup_card">
                      <div className="popup_card_logo">
                        <img
                          src={arrow_left}
                          alt="GETMEE"
                          onClick={this.toggleModalContactUs}
                          className="cp"
                        />
                        <img
                          src={marketPlaceLogoImg}
                          alt="GETMEE"
                          height="50"
                        />
                      </div>
                      <div className="popup_card_details">
                        <div className="pc_details_img">
                          <img
                            src={course?.courseImgUrl}
                            className="w-100 img-fluid"
                          />
                        </div>
                        <div className="pc_details_des">
                          <h3>{course?.courseName}</h3>
                          <p>{course?.courseProviderName}</p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="popup_info">
                      <p>
                        Powered by <a href="#">Terms</a> <a href="#">Privacy</a>
                      </p>
                    </div> */}
                  </div>
                </Col>
                <Col lg={6} className="">
                  <div className="popup_right">
                    <Form onSubmit={this.handleSubmit} method="POST">
                      <div className="popup_tab">
                        <label>Name*</label>
                        <input
                          name="name"
                          placeholder=""
                          type="text"
                          value={this.state.name}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="popup_tab">
                        <label>Email*</label>
                        <input
                          name="email"
                          label="Email (Required)"
                          placeholder=""
                          type="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="popup_tab">
                        <label htmlFor="mobileNo">Mobile No*</label>
                        <PhoneInput
                          country={"au"}
                          name="mobileNo"
                          id="mobileNo"
                          preferredCountries={["au", "in"]}
                          value={this.state.mobileNo}
                          enableLongNumbers={true}
                          // enableAreaCodes={true}
                          enableSearch={true}
                          disableSearchIcon={true}
                          inputProps={{
                            required: true,
                          }}
                          onChange={this.handlePhoneInputChange}
                        />
                      </div>
                      <div className="popup_tab">
                        <label>Message*</label>
                        <textarea
                          name="message"
                          value={this.state.message}
                          onChange={this.handleChange}
                          rows="5"
                        ></textarea>
                      </div>
                      <FormGroup className="mb-0">
                        <div className="popup_tab_btn">
                          <Button
                            type="submit"
                            color="primary"
                            className="w-100 mr-1"
                          >
                            Submit
                          </Button>
                        </div>
                      </FormGroup>
                    </Form>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    modal_contactus: state.marketplace.modal_contactus,
    contactUsData: state.marketplace.contactUsData,
  };
};

export default connect(mapStateToProps)(MarketPlaceContactUs);

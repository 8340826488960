import React from "react";
import { Row, Col, Container } from "reactstrap";
import ContactUs from "./../common/contactUs";
import marketPlaceLogoImg from "./../../../assets/images/marketplace-logo.png";
import appstore from "./../../../assets/images/appstore.png";
import googleplay from "./../../../assets/images/googleplay.png";
import twitter from "./../../../assets/images/icon-twitter.svg";
import facebook from "./../../../assets/images/icon-facebook.svg";
import instagram from "./../../../assets/images/icon-instagram.svg";
import linkedin from "./../../../assets/images/icon-linkedin.svg";

import { Link } from "react-router-dom";

const Footer = () => {
  const stopNow = (e) => {
    e.preventDefault();
  };

  return (
    <React.Fragment>
      <ContactUs />
      <footer className="footer-marketplace">
        <Container>
          <Row>
            <Col sm={3}>
              <div className="footer_logo">
                <Link to="/marketplace/home" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={marketPlaceLogoImg} alt="GETMEE" height="70" />
                  </span>
                  <span className="logo-lg">
                    <img src={marketPlaceLogoImg} alt="GETMEE" height="70" />
                  </span>
                </Link>
                <p>
                  Introducing GetMee, the personal AI coach that teaches users
                  to speak more clearly, directly and effectively.
                </p>
                <div className="footer_social">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/aiforpersonaldevelopment/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={facebook} alt="getmee facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/getmeeai/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={instagram} alt="getmee instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/getmeeai"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={twitter} alt="getmee twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/get-mee"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={linkedin} alt="getmee linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col sm={3}>
              <div className="footer_tab">
                <h3>Institutes</h3>
                <ul>
                  <li>
                    <Link to="/marketplace/institution/you-study-international-college">
                      YouStudy International College
                    </Link>
                  </li>
                  <li>
                    <Link to="/marketplace/institution/english-priority">
                      English Priority
                    </Link>
                  </li>
                  <li>
                    <Link to="/marketplace/institution/pacific-training-group">
                      Pacific Training Group
                    </Link>
                  </li>
                  <li>
                    <Link to="/marketplace/institution/education-center-of-australia">
                      ELSIS Language School
                    </Link>
                  </li>
                  <li>
                    <Link to="/marketplace/institution/ames-australia">
                      AMES Australia
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm={3}>
              <div className="footer_tab">
                <h3>About</h3>
                <ul>
                  <li>
                    <a
                      href="https://getmee.ai/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Getmee.ai
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://getmee.ai/blog/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://getmee.ai/website-tc/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Website T&C
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://getmee.ai/website-privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Website Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://getmee.ai/app-data-privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      App Data Privacy
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://getmee.ai/app-tc/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      App T&C
                    </a>
                  </li>
                  {/* <li>
                    <a href="#" onClick={stopNow}>
                      Community policy
                    </a>
                  </li> */}
                </ul>
              </div>
            </Col>
            <Col sm={3}>
              <div className="footer_tab">
                <h3>Download App</h3>
                <div className="app_icons">
                  <a
                    href="https://apps.apple.com/app/id1642870008"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={appstore} alt="getmee ios" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=ai.getmee.education"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={googleplay} alt="getmee android" />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="copy">
                © {new Date().getFullYear()} <span>GetMee.</span> All rights
                reserved.
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Col,
  Form,
  Input,
} from "reactstrap";
import { getSearchList } from "./../services";
import MSG, { notifyError } from "../../../utils/Helper";
import {
  toggleInstitutionMenu,
  updateFilterOptionAction,
  toggleSearch,
  toggleMobileSearch,
} from "./../../../store/reducers/marketplace";
import marketPlaceLogoImg from "./../../../assets/images/marketplace-logo.png";
import SearchBarMobile from "./SearchBarMobile";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      searchData: [],
      typing: false,
      typingTimeout: 0,
      isBurgerMenuOpen: false,
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if (this.props.isSearchOpen) {
        this.props.dispatch(toggleSearch());
      }
    }
  }

  toggleInstitution = (e) => {
    e.preventDefault();
    this.props.dispatch(toggleInstitutionMenu());
  };

  handleMenuToggle = () => {
    this.setState({
      isBurgerMenuOpen: !this.state.isBurgerMenuOpen,
    });
  };

  handleMobileSearchToggle = () => {
    this.props.dispatch(toggleMobileSearch());
  };

  onSearchEnterHandler = (e) => {
    if (e.keyCode === 13) {
      // get data
    }
  };

  onSearchInputChange = ({ currentTarget: input }) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    if (!_.isEmpty(_.trim(input.value)) && !this.props.isSearchOpen) {
      this.props.dispatch(toggleSearch());
    }
    if (_.isEmpty(_.trim(input.value)) && this.props.isSearchOpen) {
      this.props.dispatch(toggleSearch());
    }
    this.setState({
      search: input.value,
      typing: false,
      typingTimeout: setTimeout(async () => {
        await this.getSearchListData();
      }, 2000),
    });
  };

  getSearchListData = async () => {
    try {
      const { data: res } = await getSearchList({
        search: this.state.search,
      });
      if (res.success == true) {
        this.setState({
          searchData: [...res.response],
        });
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.message != "") {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* <div className="web_topbar">
          <div className="container-fluid">
            <div className="topbar">
              <h3>Published at Thursday, 12 Jan, 2023, 7:00am</h3>
              <a className="topbar_btn" href="#">Exit Preview</a>
            </div>
          </div>
        </div> */}
        {this.props.isMobileSearchOpen && (
          <SearchBarMobile
            handleMobileSearchToggle={this.handleMobileSearchToggle}
            onChange={this.onSearchInputChange}
            searchValue={this.state.search}
            searchData={this.state.searchData}
          />
        )}
        <header id="page-topbar">
          <div className="container-fluid">
            <Navbar className="navbar-mobile">
              <div className="get_started">
                <Link
                  className="waves-effect waves-light btn btn-edu-primary h-100 font-weight-bold"
                  to="/"
                  onClick={this.props.scrollToContactUs}
                >
                  Join Us
                </Link>
                <NavbarBrand tag={Link} to="/marketplace/home">
                  <span className="logo-sm">
                    <img src={marketPlaceLogoImg} alt="GETMEE" height="50" />
                  </span>
                </NavbarBrand>
                <span
                  className="mdi mdi-magnify gt-searchbtn"
                  onClick={this.handleMobileSearchToggle}
                ></span>
                <button
                  aria-label="Toggle navigation"
                  type="button"
                  className="navbar-toggler"
                  onClick={this.handleMenuToggle}
                >
                  <span className="mdi mdi-menu"></span>
                </button>
              </div>
              <Collapse isOpen={this.state.isBurgerMenuOpen} navbar>
                <Nav className="me-auto" navbar>
                  <NavItem>
                    <NavLink tag={Link} to="/marketplace/home">
                      Browse Courses
                    </NavLink>
                  </NavItem>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Institutions
                      <i className="fa fa-caret-down"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        tag={Link}
                        to="/marketplace/institution/you-study-international-college"
                      >
                        YouStudy International College
                      </DropdownItem>
                      <DropdownItem
                        tag={Link}
                        to="/marketplace/institution/english-priority"
                      >
                        English Priority
                      </DropdownItem>
                      <DropdownItem
                        tag={Link}
                        to="/marketplace/institution/pacific-training-group"
                      >
                        Pacific Training Group
                      </DropdownItem>
                      <DropdownItem
                        tag={Link}
                        to="/marketplace/institution/education-center-of-australia"
                      >
                        ELSIS Language School
                      </DropdownItem>
                      <DropdownItem
                        tag={Link}
                        to="/marketplace/institution/ames-australia"
                      >
                        AMES Australia
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
                {/* <NavbarText>Simple Text</NavbarText> */}
              </Collapse>
            </Navbar>

            <div className="navbar-header">
              <div className="row w-100">
                <div className="col-8">
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="navbar-brand-box">
                      <Link to="/marketplace/home" className="logo logo-dark">
                        <span className="logo-sm">
                          <img
                            src={marketPlaceLogoImg}
                            alt="GETMEE"
                            height="50"
                          />
                        </span>
                        <span className="logo-lg">
                          <img
                            src={marketPlaceLogoImg}
                            alt="GETMEE"
                            height="60"
                          />
                        </span>
                      </Link>
                    </div>
                    <div className="navbar_link">
                      <ul>
                        <li>
                          <Link
                            to="/marketplace/home"
                            className="browse-courses"
                          >
                            Browse Courses
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="navbar_search gt-search-form-autocomplete" ref={this.wrapperRef}>
                      <Form
                        className="app-search p-0 d-none d-lg-block"
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <div className="position-relative">
                          <Input
                            type="search"
                            onChange={this.onSearchInputChange}
                            value={this.state.search}
                            onKeyDown={this.onSearchEnterHandler}
                            style={{
                              borderColor: "#E4E4E4",
                              backgroundColor: "#F8F8F8",
                            }}
                            placeholder="Search"
                          />
                          <span className="ri-search-line"></span>
                        </div>
                        {this.props.isSearchOpen && (
                          <ul
                            className="gt-unstyled-list gt-search-form-autocomplete-suggestions-with-image"
                            role="listbox"
                          >
                            <li>
                              {/* <div className="gt-search-form-autocomplete-suggestion-block-list-item">
                              <a
                                aria-label="Search: javascript"
                                role="option"
                                href="/courses/search/?q=javascript&amp;src=sac&amp;kw=javascript"
                                className="gt-search-form-autocomplete-suggestion"
                              >
                                <div className="gt-block-list-item-content">
                                  <div className="gt-search-form-autocomplete-group gt-search-form-autocomplete-group-search">
                                    <div className="gt-search-form-autocomplete-group-icon">
                                      <i className="fa fa-search"></i>
                                    </div>
                                    <div className="gt-search-form-autocomplete-suggestion-content">
                                      javascript
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div> */}
                              {!!this.state.searchData &&
                                this.state.searchData.map((course) => {
                                  return (
                                    <div className="gt-search-form-autocomplete-suggestion-block-list-item" key={course._id}>
                                      <Link
                                        aria-label={`Course: ${course.courseName} - Institution: ${course.courseProviderName}`}
                                        role="option"
                                        to={`/marketplace/course-detail/${course._id}`}
                                        className="gt-search-form-autocomplete-suggestion"
                                      >
                                        <div className="gt-block-list-item-content">
                                          <div className="gt-search-form-autocomplete-group">
                                            <div className="gt-search-form-autocomplete-group-course-img">
                                              <img
                                                alt={`${course.courseName}`}
                                                src={`${course.courseImgUrl}`}
                                                width="32"
                                                height="32"
                                              />
                                            </div>
                                            <div className="gt-search-form-autocomplete-suggestion-content">
                                              <div className="gt-search-form-autocomplete-suggestion-content">
                                                {course.courseName}
                                              </div>
                                              <div className="gt-search-form-autocomplete-suggestion-details">
                                                <div className="gt-heading-xs">
                                                  Institution
                                                </div>
                                                <div className="gt-text-xs gt-search-form-autocomplete-suggestion-instructor-name">
                                                  {course.courseProviderName}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  );
                                })}
                            </li>
                          </ul>
                        )}
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex justify-content-end align-items-center h-100">
                    <div className="navbar_link">
                      <ul>
                        <li>
                          <a href="#" onClick={this.toggleInstitution}>
                            Institutions
                          </a>
                          {this.props.institutionOpen && (
                            <ul className="navbar_dropdown">
                              <li>
                                <Link to="/marketplace/institution/you-study-international-college">
                                  YouStudy International College
                                </Link>
                              </li>
                              <li>
                                <Link to="/marketplace/institution/english-priority">
                                  English Priority
                                </Link>
                              </li>
                              <li>
                                <Link to="/marketplace/institution/pacific-training-group">
                                  Pacific Training Group
                                </Link>
                              </li>
                              <li>
                                <Link to="/marketplace/institution/education-center-of-australia">
                                  ELSIS Language School
                                </Link>
                              </li>
                              <li>
                                <Link to="/marketplace/institution/ames-australia">
                                  AMES Australia
                                </Link>
                              </li>
                            </ul>
                          )}
                        </li>
                        {/* <li>
                          <a href="#">Login</a>
                        </li> */}
                      </ul>
                    </div>
                    <div className="get_started">
                      <Link
                        className="waves-effect waves-light btn btn-edu-primary h-100 font-weight-bold"
                        to="/"
                        onClick={this.props.scrollToContactUs}
                      >
                        Join Us
                      </Link>

                      {/* <ProfileMenu /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    institutionOpen: state.marketplace.institutionOpen,
    isMobileSearchOpen: state.marketplace.isMobileSearchOpen,
    isSearchOpen: state.marketplace.isSearchOpen,
    filters: state.marketplace.filters,
  };
};

export default connect(mapStateToProps)(Header);

// Actions
const TOGGLE_PLAYER_MODAL = "TOGGLE_PLAYER_MODAL";
const SET_VIDEO_DATA = "SET_VIDEO_DATA";
const START_LOADER = "START_LOADER";
const STOP_LOADER = "STOP_LOADER";
const START_FORM_LOADER = "START_FORM_LOADER";
const STOP_FORM_LOADER = "STOP_FORM_LOADER";
const SET_JOURNALING_QUESTION = "SET_JOURNALING_QUESTION";
const TOGGLE_JOURNALING_QUESTION_MODAL = "TOGGLE_JOURNALING_QUESTION_MODAL";
const TOGGLE_DEMO_VIDEO = "TOGGLE_DEMO_VIDEO";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

// Action Creators
export function togglePlayerModal() {
  return { type: TOGGLE_PLAYER_MODAL };
}

export function setVideoPlayerData(payload) {
  return { type: SET_VIDEO_DATA, payload };
}

export function startLoader() {
  return { type: START_LOADER };
}

export function stopLoader() {
  return { type: STOP_LOADER };
}

export function startFormLoader() {
  document.body.classList.add("scroll-off");
  return { type: START_FORM_LOADER };
}

export function stopFormLoader() {
  document.body.classList.remove("scroll-off");
  return { type: STOP_FORM_LOADER };
}

export function toggleJournalingQuestionModal() {
  return { type: TOGGLE_JOURNALING_QUESTION_MODAL };
}

export function setJournalingQuestionData(payload) {
  return { type: SET_JOURNALING_QUESTION, payload };
}

export function toggleDemoVideoPlayerModal() {
  return {
    type: TOGGLE_DEMO_VIDEO,
    payload: {
      contentId: "",
      url: "https://getmee-static.s3.ap-southeast-2.amazonaws.com/GetMeePersonalGrowthVideoDemo.mp4",
      img: "",
      title:
        "Demo Video - Personal Growth Accelerator Platform demonstration video",
      category: "",
      coach: "",
    },
  };
}

export const changeSidebarType = (sidebarType, isMobile) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  };
};

const initialState = {
  videoplayerModal: false,
  isDemoVideoOpen: false,
  journalingQuestionModal: false,
  refreshing: false,
  refreshingForm: false,
  leftSideBarType: "default",
  videoData: {},
  journalingQuestionData: {},
};

// Reducer
export default function reducer(
  state = initialState,
  { type, payload = null }
) {
  switch (type) {
    case TOGGLE_PLAYER_MODAL:
      return modalPlayerToggle(state);
    case SET_VIDEO_DATA:
      return dosetVideoPlayerData(state, payload);
    case START_LOADER:
      return loaderStart(state);
    case STOP_LOADER:
      return loaderStop(state);
    case START_FORM_LOADER:
      return loaderFormStart(state);
    case STOP_FORM_LOADER:
      return loaderFormStop(state);
    case TOGGLE_JOURNALING_QUESTION_MODAL:
      return modalJournalingQuestionToggle(state);
    case SET_JOURNALING_QUESTION:
      return dosetJournalingQuestionData(state, payload);
    case TOGGLE_DEMO_VIDEO:
      return modalDemoVideoPlayerToggle(state, payload);
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: payload.sidebarType,
      };
    default:
      return state;
  }
}

function modalPlayerToggle(state) {
  return {
    ...state,
    videoplayerModal: !state.videoplayerModal,
  };
}

function dosetVideoPlayerData(state, payload) {
  return {
    ...state,
    videoplayerModal: true,
    journalingQuestionModal: false,
    isDemoVideoOpen: false,
    videoData: { ...payload },
    journalingQuestionData: {},
  };
}

function loaderStart(state) {
  return {
    ...state,
    refreshing: true,
  };
}
function loaderStop(state) {
  return {
    ...state,
    refreshing: false,
  };
}

function loaderFormStart(state) {
  return {
    ...state,
    refreshingForm: true,
  };
}
function loaderFormStop(state) {
  return {
    ...state,
    refreshingForm: false,
  };
}

function modalJournalingQuestionToggle(state) {
  return {
    ...state,
    videoplayerModal: false,
    isDemoVideoOpen: false,
    journalingQuestionModal: !state.journalingQuestionModal,
  };
}

function dosetJournalingQuestionData(state, payload) {
  return {
    ...state,
    journalingQuestionData: { ...payload },
  };
}

function modalDemoVideoPlayerToggle(state, payload) {
  return {
    ...state,
    videoplayerModal: !state.videoplayerModal,
    journalingQuestionModal: false,
    videoData: { ...payload },
    journalingQuestionData: {},
  };
}

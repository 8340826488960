import React, { Suspense } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import LoaderCircle from "../components/common/loader-circle";
import MarketPlaceLayout from "./../components/marketplace/layout";

const MarketPlaceRoute = ({
  component: Component,
  isAuthenticated,
  refreshing,
  routefor,
  layout,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <LoaderCircle isLoading={refreshing} />
            <MarketPlaceLayout>
              <React.Fragment>
                <Component {...props} />
              </React.Fragment>
            </MarketPlaceLayout>
          </Suspense>
        );
      }}
    />
  );
};

MarketPlaceRoute.displayName = "MarketPlace Routes";

MarketPlaceRoute.propTypes = {
  component: PropTypes.object.isRequired,
  location: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

// Retrieve data from store as props
function mapStateToProps(store) {
  return {
    isAuthenticated: store.auth.isAuthenticated,
    refreshing: store.generalActions.refreshing,
  };
}

export default withRouter(connect(mapStateToProps)(MarketPlaceRoute));
